.faq{
  padding: $space_desktop 0;
  background: #fbfbfb;
  @media screen and (max-width: 767px) {
    padding: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space_mobile;
    }
  }
  .faq-blocks{
    gap: 22px 16px;
    align-items: flex-start;
    .faq-block{
      flex-basis: calc(50% - 11px);
      border: 1px solid #ececec;
      border-radius: 15px;
      padding: 18px 30px;
      @media screen and (max-width: 991px) {
        flex-basis: 100%;
      }
      .faq-title{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        color: $c_text_100;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:after{
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background-size: auto;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='13' viewBox='0 0 20 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2.17383L10 11.0014L18 2.17383' stroke='%23FFD36A' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          transition: all 0.4s ease;
        }
      }
      .faq-text{
        display: none;
        padding-top: 10px;
      }
      &.full{
        flex-basis: 100%;
      }
      &.active{
        .faq-title{
          &:after{
            transform: rotate(180deg);
          }
        }
        .faq-text{
          display: block;
        }
      }
    }
  }
}