.background-left-blocks{
  &.new{
    .background-left-item{
      &:nth-child(5n+1) {
        &:before {
          background-image: url('../img/new-bg-left-item-1.webp')!important;
        }
      }
      &:nth-child(5n+2) {
        &:before {
          background-image: url('../img/new-bg-left-item-2.webp')!important;
          background-position: bottom center;
        }
      }
      &:nth-child(5n+3) {
        &:before {
          background-image: url('../img/new-bg-left-item-3.webp')!important;
        }
      }
      &:nth-child(5n+4) {
        &:before {
          background-image: url('../img/new-bg-left-item-4.webp')!important;
        }
      }
      &:nth-child(5n+5) {
        &:before {
          background-image: url('../img/new-bg-left-item-5.webp')!important;
        }
      }
    }
  }
  .background-left-blocks-top{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h2{
      margin-bottom: 20px;
    }
    span{
      display: block;
      max-width: 924px;
      margin: 0 auto;
    }
  }
  .background-left-items{
    gap: 40px;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    &.three-columns{
      justify-content: flex-start;
      .background-left-item{
        flex-basis: calc(33.33% - 26.66px);
      }
    }
    .background-left-item{
      flex-basis: calc(50% - 20px);
      border-radius: 15px;
      background: #FFF;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
      padding: 40px 40px 40px 80px;
      position: relative;
      overflow: hidden;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40px;
      }
      &:nth-child(5n+1){
        &:before{
          background-image: url('../img/bg-left-item-1.webp');
        }
      }
      &:nth-child(5n+2){
        &:before{
          background-image: url('../img/bg-left-item-2.webp');
        }
      }
      &:nth-child(5n+3){
        &:before{
          background-image: url('../img/bg-left-item-3.webp');
        }
      }
      &:nth-child(5n+4){
        &:before{
          background-image: url('../img/bg-left-item-4.webp');
        }
      }
      &:nth-child(5n+5) {
        &:before {
          background-image: url('../img/new-bg-left-item-1.webp');
        }
      }
      @media screen and (max-width: 991px) {
        flex-basis: 100%;
      }
      @media screen and (max-width: 479px) {
        padding: 25px 25px 25px 65px;
      }
      .block-title{
        color: $c_text_100;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 16px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      p{
        margin-bottom: 16px;
        font-size: 14px;
      }
      ul{
        li{
          position: relative;
          padding-left: 15px;
          margin-bottom: 10px;
          &:before{
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background: $c_orange;
            border-radius: 50%;
            top: 6px;
            left: 0;
          }
        }
      }
      .orange-link{
        color: $c_yellow_medium;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        text-decoration-line: underline;
      }
    }
  }
}