.center-blocks{
  .center-blocks-top{
    text-align: center;
    margin-bottom: $space_desktop;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
    }
    h2{
      margin-bottom: 20px;
    }
    span{
      display: block;
      max-width: 924px;
      margin: 0 auto;
    }
  }
  .center-blocks-items{
    gap: 60px 80px;
    margin-bottom: $space_desktop;
    justify-content: flex-start;
    @media screen and (max-width: 991px) {
      margin-bottom: $space_tablet;
      gap: 40px;
    }
    .center-blocks-item{
      border-left: 4px solid $c_yellow_medium;
      padding-left: 20px;
      flex-basis: calc(50% - 40px);
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
      .block-title{
        color: $c_text_100;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 16px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      p{
        margin-bottom: 16px;
        font-size: 14px;
      }
      ul{
        li{
          position: relative;
          padding-left: 15px;
          margin-bottom: 10px;
          &:before{
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background: $c_orange;
            border-radius: 50%;
            top: 6px;
            left: 0;
          }
        }
      }
      .orange-link{
        color: $c_yellow_medium;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        text-decoration: underline;
        transition: all 0.4s ease;
        &:hover{
          color: $c_orange;
        }
      }
    }
  }
  &.three-columns{
    .center-blocks-item{
      flex-basis: calc(33.33% - 53.33px);
      @media screen and (max-width: 991px) {
        flex-basis: calc(33.33% - 26.7px);
      }
      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
    }
  }
}